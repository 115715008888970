/**
 * Provides the order as resource with RESTful routes.
 */
(function() {
    'use strict';

    angular.module('app').factory('OrderFactory', OrderFactory);

    function OrderFactory($resource, $routeParams) {
        return $resource('/order/:id', {}, {
            update: {method: 'PUT', params: {id: id}},
            query: {method: 'GET', params: {id: 'index', page: 1}},
            get: {method: 'GET', params: {id: id}},
            statuses: {method: 'GET', params: {id: 'statuses'}, isArray:true},
            destinations: {method: 'GET', params: {id: 'destinations'}, isArray:true},
            carriers: {method: 'GET', params: {id: 'carriers'}, isArray:true},
            paymentMade: {method: 'GET', params: {id: 'paymentMade'}, isArray:true},
            getOrderCount: {method: 'GET', params: {id: 'getOrderCount'}},
            braintreeToken: {method: 'GET', params: {id: 'braintreeToken'}}
        });

        function id() {
            return $routeParams.id;
        }
    }
})();
