(function() {
    'use strict';

    angular.module('app').directive('mcafeeTag', mcafeeTag);

    function mcafeeTag() {
        var injectScript = function($element) {
            var scriptTag = '<a target="_blank" href="https://www.mcafeesecure.com/verify?host='+location.host+'"><img class="mfes-trustmark mfes-trustmark-hover" border="0" src="//cdn.ywxi.net/meter/'+location.host+'/102.gif" width="120" height="50" title="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams" alt="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams" oncontextmenu="window.open(\'https://www.mcafeesecure.com/verify?host='+location.host+"'); return false;\"></a>";
            $element.append(scriptTag);
        };

        return {
            link: function($scope, $element) {
                injectScript($element);
            }
        };
    }
})();
