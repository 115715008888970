(function() {
    'use strict';

    angular.module('app').factory('OptionParserFactory', OptionParserFactory);

    function OptionParserFactory() {
        return {parse: parseOptions};

        /**
         * Sets the key of any nested Options to the name of that options field.
         *
         * @param {*} owner The object whose Options are to be parsed.
         * @returns {*} If an object was passed and it had options an object will be returned with options that have
         *              been parsed into an object.
         */
        function parseOptions(owner) {
            if (typeof owner !== "object" || !owner.hasOwnProperty('options')) {
                return owner;
            }

            var parsedOptions = {};

            for (var id in owner.options) {
                if (!owner.options.hasOwnProperty(id)) continue;

                var parsed = {};

                if ((typeof owner.options[id].options !== 'undefined') && owner.options[id].options.length > 0) {
                    parsed = parseOptions(owner.options[id]);
                } else {
                    parsed = owner.options[id];
                }

                parsedOptions[owner.options[id].field.name] = parsed;
            }

            owner.options = parsedOptions;

            return owner;
        }
    }
})();
