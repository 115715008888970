//angularjs debounce
(function() {
    angular.module('app').factory('DebounceFactory', DebounceFactory);

    function DebounceFactory($timeout) {
        return function(callback, interval) {
            var timeout = null;
            return function() {
                $timeout.cancel(timeout);
                timeout = $timeout(function () {
                    callback.apply(this, arguments);
                }, interval);
            };
        };
    }
})();
