(function() {
    'use strict';

    angular.module('app').directive('currencyInput', function($filter) {

        return {
            require: 'ngModel',
            link: function(scope, element, attrs, modelCtrl) {

                var regex = /^[1-9]\d*(((.\d{3}){1})?(\,\d{0,2})?)$/;
                modelCtrl.$parsers.push(function (inputValue) {

                    var transformedInput = inputValue;
                    if (regex.test(transformedInput)) {

                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                        return transformedInput;
                    } else {

                        transformedInput = transformedInput.substr(0, transformedInput.length-1);
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                        return transformedInput;
                    }
                });
            }
        };
    });
})();

