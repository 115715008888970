(function() {
    'use strict';

    angular.module('app').factory('AddressFactory', AddressFactory);

    function AddressFactory($resource) {
        return $resource('/address/:id', {}, {
            validate: {method: 'GET', params: {id: 'validate'}, isObject:true}
        });
    }
})();
