(function() {
    'use strict';

    angular.module('app').controller('IdeasCustomersCarouselController', IdeasCustomersCarouselController);

    function IdeasCustomersCarouselController($scope) {
        $scope.initCarouselData = function(page){
            switch (page) {
                case 'awareness':
                    $scope.slides = [
                        {
                            image: '/images/pages-assets/kathleen-freed.jpg',
                            text: 'I must say, the quick & easy shopping/customizing of our wristbands - 600 ' +
                            'total, by RapidWristbands.com was amazing! They offered 200 free bands with each ' +
                            'purchase which was extremely helpful in our endeavor to raise awareness of stroke ' +
                            'victims, young & old, due to my 46 year old cousin being a recent victim. We have been ' +
                            'able to thus far put them on 400+ wrists, that way everyone who sees our black & ' +
                            'gold wristbands knows they are for a worthy cause!"',
                            customer: 'Kathleen Freed, cousin of stroke victim'
                        },
                        {
                            image: '/images/pages-assets/robin-phillips.jpg',
                            text: 'My husband was killed in a motorcycle accident almost 5 years ago. ' +
                            'After his death, The Down Biker Fund was started in his name to help anyone ' +
                            'that was involved in a catastrophic motorcycle accident. Our mission to try and ' +
                            'get to the impacted people as soon as possible after the accident to help relieve ' +
                            'them of some of the financial burden. I ordered wristbands online through ' +
                            'RapidWristbands.com as an affordable way to raise awareness and funds for our ' +
                            'non-profit. The website was easy to use and the ordering process was straight ' +
                            'forward. I was amazing with how quickly I received the bands! We are so thankful ' +
                            'we found RapidWristbands.com and can\'t wait until our next shipment arrives!',
                            customer: 'Robin Phillips, founder of the Down Biker Fund'
                        },
                        {
                            image: '/images/pages-assets/christina-jahner.jpg',
                            text: 'Our son Hunter has childhood apraxia of speech (CAS), a motor speech disorder. ' +
                            'We decided to order wristbands as an affordable way to raise awareness about CAS and ' +
                            'support our son. We couldn\'t be happier with how our wristbands turned out! We used ' +
                            'the wristbands for a CAS awareness walk and handed the bracelets out to all our ' +
                            'supporters. We love that we could order kid and adult sizes, so everyone has a ' +
                            'wristband to wear!',
                            customer: 'Christina Jahner, mother of son with speech disorder'
                        }
                    ];
                    break;

                case 'cancer':
                    $scope.slides = [
                        {
                            image: '/images/pages-assets/danielle-schols.jpg',
                            text: 'Just a few weeks before our family reunion, one of our brothers was diagnosed ' +
                            'with stage 4, small cell carcinoma. As a way to show support we quickly ordered white ' +
                            'bracelets that would represent his battle with lung cancer. The bracelets arrived ' +
                            'earlier than expected and 100+ family members wore the bracelets for the weekend and ' +
                            'after. I am so thankful RapidWristbands.com was there for us during our time of need. ' +
                            'We will definitely be ordering again soon.',
                            customer: 'Danielle Schols, sister of lung cancer patient'
                        },
                        {
                            image: '/images/pages-assets/paul-thompson.jpg',
                            text: 'When my best friend found out he had cancer, we all wanted him to know just how ' +
                            'much he meant to us. The wristbands at RapidWristbands.com were the perfect solution. ' +
                            'They were easy to order online, shipped so quickly, and the price just couldn\'t be ' +
                            'beat. The look on my buddy\'s face when he saw them on all of our friends\' wrists was ' +
                            'incredible.',
                            customer: 'Paul Thompson, best friend of cancer patient'
                        },
                        {
                            image: '/images/pages-assets/philip-bosanko.jpg',
                            text: 'I love my custom wristbands from RapidWristbands.com because they are a reminder ' +
                            'of everything I have overcome. In 2006, I was diagnosed with Stave IV cancer and only ' +
                            'given a year to live. "Cancer sucks" and "Cancer Hates Smiles" were two of my favorite ' +
                            'expressions throughout treatment. Over ten years later and I am still alive and ' +
                            'stronger than ever; that\'s why I ordered cancer-themed wristbands with my favorite ' +
                            'cancer sayings in lavender. I chose lavender wristbands this time because it represents ' +
                            'all cancers so they are more versatile. Throughout the year I attend cancer support ' +
                            'groups, fundraisers, and youth camps. The looks on people\'s faces when I pass out ' +
                            'cancer positivity bracelets is always priceless. Thank you RapidWristbands.com!',
                            customer: 'Phil Bosanko, cancer survivor'
                        }
                    ];
                    break;

                case 'military':
                    $scope.slides = [
                        {
                            image: '/images/pages-assets/lisa-marino.jpg',
                            text: 'RapidWristbands.com has been easy to work with, offers the best pricing, ' +
                            'every delivery has been on time, and the quality has been superb. They have made it ' +
                            'possible for our team to get over 100,000+ “Pray 4 Police” wristbands to our officers, ' +
                            'their families and the supportive citizens of Houston. We strongly trust them and ' +
                            'recommend their services to anyone ordering wristbands in small or large quantities. ' +
                            'We deeply appreciate their commitment to supporting all Law Enforcement and First ' +
                            'Responders too.',
                            customer: 'Lisa Marino,  Houston Police Officers\' Union'
                        },
                        {
                            image: '/images/pages-assets/shelly-cote.jpg',
                            text: 'Mutts Mending Military is a program that pairs rescue dogs with Veterans that ' +
                            'are living with PTSD. The wristbands we ordered are going to help us educate and raise ' +
                            'awareness to benefit Veterans. I Received my wristbands today and wanted to reach out ' +
                            'and say that you all are awesome! They are perfect and even better quality than I ' +
                            'expected. Great customer service and awesome communication. Your promotions and sales ' +
                            'make it easy on the purse. Thank you very much, I will definitely be back for more ' +
                            'in the future.',
                            customer: 'Shelly Cote, Mutts Mending Military'
                        },
                        {
                            image: '/images/pages-assets/morgan-burns.jpg',
                            text: 'We love ordering police wristbands from RapidWristbands.com with a thin blue ' +
                            'line because it is a symbol that is immediately recognized within the law enforcement ' +
                            'community. We ordered custom wristbands that say "Remember the Fallen" for our Police ' +
                            'Department to give our at numerous community events and they were a huge hit. ' +
                            'RapidWristbands.com made ordering the wristbands very simple. We simply described what ' +
                            'design we wanted on the wristbands, and the RapidWristbands.com team made it happen! ' +
                            'We cannot wait to order more wristbands soon!',
                            customer: 'Morgan Burns, Marketing Coordinator for the City of Roanoke'
                        }
                    ];
                    break;

                case 'fundraiser':
                    $scope.slides = [
                        {
                            image: '/images/pages-assets/nassim-rossi.jpg',
                            text: 'I bought silicone wristbands as an affordable way for my daughter and her ' +
                            'friends to raise money to fight global warming. The wristbands were a big hit, and ' +
                            'the girls quickly exceeded their fundraising goal! We bought 200 bracelets for only ' +
                            '$99 and the girls have already raised $149! Designing and ordering the wristbands was ' +
                            'super easy and fun. I will definitely look to RapidWristbands.com for future needs.',
                            customer: 'Nassim Rossi, mother of daughter that is fighting to end global warming'
                        },
                        {
                            image: '/images/pages-assets/danielle-siemer.jpg',
                            text: 'We ordered 200 bands and sold close to 150 in the first day! All the profit is ' +
                            'going to help Mario in his search for his kidney donor. Ordering was easy, shipping was ' +
                            'fast and the bands were a great way to get his message out and raise some money for his ' +
                            'cause. We are up to about $475 raised from the bands. It has been awesome.',
                            customer: 'Danielle Siemer, kidney fundraiser organizer'
                        },
                        {
                            image: '/images/pages-assets/catherine-cullen.jpg',
                            text: 'The ordering experience was superb! I had absolutely no problem picking them out ' +
                            'online. It was a cinch and they were delivered within 48 hours which kind of amazed me! ' +
                            'I thought the price was exceptional because if you buy 100 you get 200 free. This ' +
                            'enabled us to sell three times as many as we would have if we only had 100 bands. We ' +
                            'definitely will order from you again next summer for our program! With funding cuts in ' +
                            'the Arts we have to think of different ways to provide arts education for the students ' +
                            'in our region. I appreciate your company and how you assisted us in coming up with ' +
                            'last-minute funds that really really helped us! We made 120% profit!',
                            customer: 'Dr. Catherine Richmond Cullen, Pennsylvania Council of the Arts'
                        }
                    ];
                    break;

                case 'event':
                    $scope.slides = [
                        {
                            image: '/images/pages-assets/coleen-peterson.jpg',
                            text: 'I was very happy with our wristband order - they look great and fit nicely ' +
                            'for all different sized wrists. We are using them at our 40-year class reunion this ' +
                            'weekend. Our online ordering was easy, the price was appealing and the speed of ' +
                            'delivery was super! I\'d order from RapidWristbands.com time and time again!',
                            customer: 'Coleen Peterson, 40-year class reunion organizer'
                        },
                        {
                            image: '/images/pages-assets/zachery-harris.jpg',
                            text: 'I came across RapidWristbands.com after doing a Google search for event ' +
                            'wristbands. I decided to buy their Tyvek wristbands for an event because they ' +
                            'were affordable and we were able to customize the font and color so that the ' +
                            'wristbands matched the rest of our branding. My ordering experience with ' +
                            'RapidWristbands.com was flawless with super quick delivery! The site was very ' +
                            'easy to navigate and I was tremendously pleased with the finished product for ' +
                            'our event.',
                            customer: 'Zachery Harris, Iron Bred Motorcycle Club'
                        }
                    ];
                    break;

                case 'memorial':
                    $scope.slides = [
                        {
                            image: '/images/pages-assets/christina-austin.jpg',
                            text: 'My brother Josh passed away in February and August 19th would have been ' +
                            'his 29th birthday. We decided to do a candle light vigil on his birthday and I ' +
                            'wanted to have something to give to people in memory of him. That\'s when ' +
                            'I came across your website and figured the bracelets would be great to give ' +
                            'out to the people who attended the vigil. The wristbands were perfect! I am ' +
                            'extremely happy with how they turned out. Everyone that attended my brother\'s ' +
                            'vigil was so touched when I passed out the wristbands. You guys did a fantastic ' +
                            'job and I will definitely be recommending your company to other people! ' +
                            'Thank you so much RapidWristbands.com!',
                            customer: 'Christina Austin, vigil organizer'
                        },
                        {
                            image: '/images/pages-assets/helene-gardiner.jpg',
                            text: 'We were planning the details of a memorial when we came across ' +
                            'RapidWristbands.com. We planned and held the memorial within two weeks so ' +
                            'we needed in-remembrance bands that could get here quickly. We decided to order ' +
                            'custom wristbands from RapidWristbands.com and had all the guests wear their ' +
                            'bracelets to the service. The wristbands were well-received by everyone and ' +
                            'guests loved being able to leave the service with a keepsake to remember ' +
                            'Danny by. The bracelets were perfect and I was so happy we received them in ' +
                            'only a couple of days! The color, print and quality of the bands was ' +
                            'excellent! The ordering process was quick and easy.',
                            customer: 'Helene Gardiner, memorial organizer'
                        },
                        {
                            image: '/images/pages-assets/soname-tshering.jpg',
                            text: 'When my old friend lost his son at a young age I wanted to do something to ' +
                            'let him know I was there for him. I decided to order memorial bands from ' +
                            'RapidWristbands.com and my friend was extremely touched when I gave him the ' +
                            'bracelets. The bands were super easy to order and customize. I was also impressed ' +
                            'with the quality of the bands. I am thankful RapidWristbands.com was there for me ' +
                            'in my time of need! We ordered them at the last minute and still received ' +
                            'them quickly.',
                            customer: 'Soname Tshering, memorial organizer'
                        }
                    ];
                    break;

            }
        };
    }
})();
