(function() {
    'use strict';

    angular.module('app').factory('TaxJarFactory', TaxJarFactory);

    function TaxJarFactory($resource, $routeParams) {
        return $resource('', {}, {
            checkNexus: {url: '/checkNexus/:order', method: 'POST', params: {state:state, order:order, email:email}, isArray:true},
            recalc: {url: '/recalcTax/:order', method: 'POST', params: {state:state, order:order, email:email}, isArray:true}
        });

        function order(){
            return $routeParams.order;
        }

        function email(){
            return $routeParams.email;
        }

        function state(){
            return $routeParams.state;
        }
    }
})();


