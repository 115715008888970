// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Angular
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

angular.module('app').controller('BandPreviewController', bandPreviewController);

function bandPreviewController($scope) {
    $scope.band = {styleSlug: ""};
    $scope.convertToSlug = function(Text) {
        if (Text === null) {
            return;
        }
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'');
    };

    $scope.previewStyle = function() {
        var previewStyle = '';
        if ($scope.band.activeColorColorGroup !== null) {
            previewStyle = 'o-preview__side--' + $scope.convertToSlug($scope.band.activeColorColorGroup) + '-' + $scope.band.activeColor.values.length;
        }
        return previewStyle;
    };

    $scope.init = function(band, product, productOptions) {
        $scope.band = band;
        $scope.product = product;
        $scope.productOptions = productOptions;
        $scope.wristBandPreview = true;
        return true;
    }


} // end bandPreviewController
