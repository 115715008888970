(function () {
    'use strict';

    angular.module('app').directive('onEnter', onEnter);

    /**
     * Executes the javascript in the element's attribute when enter is pressed.
     */
    function onEnter () {
        return {restrict: 'A', link: link};

        function link(scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if(event.which === 13) {
                    scope.$apply(function (){
                        scope.$eval(attrs.onEnter);
                    });

                    event.preventDefault();
                }
            });
        }
    }
})();
