/**
 * Big ups to: http://jasonwatmore.com/post/2016/01/31/AngularJS-Pagination-Example-with-Logic-like-Google.aspx
 */

(function() {
    'use strict';

    angular.module('app').factory('PagerServiceFactory', PagerServiceFactory);

    function PagerServiceFactory($rootScope) {

        var self = {};

        self.rootScope = $rootScope;
        self.getPager = getPager;
        self.subscribe = subscribe;
        self.notify = notify;

        return self;

        /**
         * Calculates the start/end pages needed for pagination.
         *
         * @param {int} totalItems
         * @param {int} currentPage
         * @param {int} pageSize
         * @returns {{
         *  totalItems: int,
         *  currentPage: int,
         *  pageSize: int,
         *  totalPages: int,
         *  startPage: int,
         *  endPage: int,
         *  startIndex: int,
         *  endIndex: int,
         *  pages: Array
         *  }}
         */
        function getPager(totalItems, currentPage, pageSize) {
            // default to first page
            currentPage = currentPage || 1;

            // default page size is 10
            pageSize = parseInt(pageSize || 10);

            // calculate total pages
            var totalPages = Math.ceil(totalItems / pageSize);

            var startPage, endPage;
            if (totalPages <= 10) {
                // less than 10 total pages so show all
                startPage = 1;
                endPage = totalPages;
            } else {
                // more than 10 total pages so calculate start and end pages
                if (currentPage <= 6) {
                    startPage = 1;
                    endPage = 10;
                } else if (currentPage + 4 >= totalPages) {
                    startPage = totalPages - 9;
                    endPage = totalPages;
                } else {
                    startPage = currentPage - 5;
                    endPage = currentPage + 4;
                }
            }

            // calculate start and end item indexes
            var startIndex = (currentPage - 1) * pageSize;
            var endIndex = startIndex + pageSize;

            // create an array of pages to ng-repeat in the pager control
            var pages = [];
            for (var i=startPage; i <= endPage; i++) {
                pages.push(i);
            }

            // return object with all pager properties required by the view
            return {
                totalItems: totalItems,
                currentPage: currentPage,
                pageSize: pageSize,
                totalPages: totalPages,
                startPage: startPage,
                endPage: endPage,
                startIndex: startIndex,
                endIndex: endIndex,
                pages: pages
            };
        }

        /**
         * Dispatches event
         */
        function notify() {
            $rootScope.$emit('notifying-service-event');
        }

        /**
         * Listen to events dispatched by notify() function
         *
         * @param scope
         * @param callback
         */
        function subscribe(scope, callback) {
            var handler = $rootScope.$on('notifying-service-event', callback);
            scope.$on('$destroy', handler);
        }
    }

})();
