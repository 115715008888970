(function() {
    'use strict';

    angular.module('app').directive('slideToggle', slideToggle);

    function slideToggle() {
        return {
            restrict: 'A',
            scope: {
                isOpen: "=slideToggle"
            },
            link: function (scope, element, attr) {
                var slideDuration = parseInt(attr.slideToggleDuration, 10) || 200;
                scope.$watch('isOpen', function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        element.stop().slideToggle(slideDuration);
                    }
                });
            }
        };
    }
})();
