(function() {
    'use strict';

    angular.module('app').controller('FundraiserController', FundraiserController);

    function FundraiserController($scope, StylePricingFactory) {
        StylePricingFactory.query().$promise.then(setDefaults);
        $scope.updatePricing = updatePricing;
        $scope.setSize = setSize;
        $scope.productURL = '';

        function updatePricing() {
            if($scope.fundraise.quantity.sizeId !== '' &&
                $scope.fundraise.styleId !== '' &&
                $scope.fundraise.salePrice >= 1){
                var costEach = 0;
                var quantityToSell = 0;
                var pricings = $scope.styles[$scope.fundraise.styleId].sizes[$scope.fundraise.sizeId].pricing;

                angular.forEach(pricings, function(value, key) {
                    if (quantityToSell <= 0 || (typeof pricings[key] !== 'undefined' &&
                        quantityToSell >= pricings[key].start)) {
                        quantityToSell = Math.round($scope.fundraise.amount/($scope.fundraise.salePrice - value.price));
                        costEach = value.price;
                    }
                });

                $scope.fundraise.cost = costEach * quantityToSell;
                $scope.fundraise.quantity = quantityToSell;
                $scope.fundraise.revenue = quantityToSell * $scope.fundraise.salePrice;
                $scope.fundraise.profit = $scope.fundraise.revenue - $scope.fundraise.cost;
                getURL();
            }
        }

        function getURL() {
            if(typeof $scope.fundraise !== 'undefined' &&
                typeof $scope.fundraise.styleId !== 'undefined') {
                var style = $scope.styles[$scope.fundraise.styleId].name
                    .toLowerCase()
                    .replace(/ /g,'-')
                    .replace(/[^\w-]+/g,'');

                var size = $scope.styles[$scope.fundraise.styleId].sizes[$scope.fundraise.sizeId].width.unit;

                $scope.productURL = 'type='+ style +'&size='+size;
            }
        }

        function setDefaults(index) {
            $scope.styles = index;
            $scope.fundraise = {
                amount: 500, salePrice: 5, quantity: '', styleId: 2, sizeId: 1, cost: '', revenue: '', profit: ''
            };
            $scope.sizes = $scope.styles[$scope.fundraise.styleId].sizes;
            $scope.fundraise.sizeId = 0;
            updatePricing();
        }

        function setSize() {
            $scope.sizes = $scope.styles[$scope.fundraise.styleId].sizes;
            $scope.fundraise.sizeId = 0;
            updatePricing();
        }
    }
})();
