/**
 * Provides a factory as a resource with RESTful routes.
 */
(function() {
    'use strict';
    angular.module('app').factory('PurchaseOrderFactory', PurchaseOrderFactory);

    function PurchaseOrderFactory($resource, $routeParams) {

        return $resource('/purchaseOrder/:id', {}, {
            update: {method: 'PUT', params: {id: id}},
            index: {isArray:true},
            get: {method: 'GET', params: {id: id}},
            refresh: {method:'GET', params: {id: 'refreshes'}, isArray:true}
        });

        function id() {
            return $routeParams.id;
        }
    }
})();
