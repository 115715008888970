(function() {
    'use strict';

    angular.module('app').factory('ProductCategoryFactory', ProductCategoryFactory);

    function ProductCategoryFactory($resource, $routeParams, OptionParserFactory) {
        return $resource('/productCategory/:id', {}, {
            update: {method: 'PUT', params: {id: id}},
            get: {method: 'GET', params: {id: id}, transformResponse: transformResponse}
        });

        function id() {
            return $routeParams.id;
        }

        function transformResponse(response) {
            // this is to make all category data an object and arrays
            response = OptionParserFactory.parse(angular.fromJson(response));
            // this is to make the product data an object with proper index
            response.product = OptionParserFactory.parse(angular.fromJson(response.product));

            return response;
        }
    }
})();
