/**
 * Used for displaying and editing cart items.
 */
(function() {
    'use strict';

    angular.module('app').controller('CartController', CartController);

    function CartController($scope, CartFactory, CartCouponCodeFactory) {
        $scope.cart = CartFactory;
        $scope.showingDetails = false;
        $scope.calcCartSubtotal = CartFactory.subTotal;
        $scope.calcCartTotal = CartFactory.cartTotal;
        $scope.cartPromoQty = CartFactory.cartPromoQtyCallBack;
        $scope.calcCartDiscount = CartFactory.calcCartDiscount;
        $scope.removeItem = removeItem;
        $scope.openCartModal = openCartModal;
        $scope.getClipartImg = getClipartImg;
        $scope.flippedIcon = flippedIcon;
        $scope.checkIfPDF = checkIfPDF;
        $scope.applyCouponCode = applyCouponCode;
        $scope.usedCouponCode = false;

        function removeItem(index) {
            CartFactory.removeItem(index);
        }

        function openCartModal(url) {
            $('.js-cart-preview').prop('src', url);
        }

        function flippedIcon(id){
            if (id.match(/^(\d*) \(flipped\)$/)) {
                return 'js-flipped';
            } else {
                return '';
            }
        }

        function checkIfPDF(id){
            return id.match(/.pdf/) ? '/images/pdf_icon.png': '/customBagging/preview/'+id;
        }

        function applyCouponCode(code) {
            CartCouponCodeFactory.get({id: code}).$promise.then(success);

            function success(result) {
                if(!result.discount) {
                    $scope.usedCouponCode = true;
                }else {
                    $scope.cart.pushCoupon(result);
                    $scope.usedCouponCode = false;
                }
            }
        }

        /**
         * Provides the URL to a specific ClipArt id
         *
         * @param  {string} id the identifier for the ClipArt
         * @return {string} the URL for the ClipArt
         */
        function getClipartImg(id) {
            var parts, src;

            if (id.match(/^(\d*)$/)) {
                src = '/clipArt/' + id + '.png';
            } else if ((parts = id.match(/^(\d*) \(flipped\)$/))) {
                src = '/clipArt/' + parts[1] + '.png%20(flipped)';
            } else {
                src = '/clipArt/' + id;
            }

            return src;
        }
    }
})();
