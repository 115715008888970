/**
 * The service to shown form errors and track them with Google Tag Manager and Hotjar.
 */
(function() {
    'use strict';

    angular.module('app').factory('FormError', FormError);

    /**
     * Handles the errors form and display the message.
     *
     * @param {Object} $window   Angular's wrapper to window
     * @return {boolean} If the field should show error or not.
     */
    function FormError($window) {

        return {

            /**
             * Checks if the form field has a valid error that should be shown.
             *
             * @param {Object} form           The form object to check for error.
             * @param {string} fieldName      The field name to check if its valid or not.
             * @param {string} validationRule The rule to be used to check the field validation.
             * @param {string} message        The message to be displayed if the field has an error.
             * @return {boolean} If the field should show error or not.
             */
            hasError: function (form, fieldName, validationRule, message) {
                if (this.showError(form, fieldName) && form[fieldName].$error[validationRule]) {
                    $('.' + fieldName + 'ErrorMsg').text(message);
                    this.pushGtmErrorEvent(fieldName, message);
                    return true;
                }
                return false;
            },

            /**
             * Checks if the form and form field has been touched or submitted.
             *
             * @param {Object} form           The form object to check for error.
             * @param {string} fieldName      The field name to check if its valid or not.
             * @return {boolean} If the field should show error or not.
             */
            showError: function (form, fieldName) {
                return (form[fieldName].$touched || form.$submitted);
            },

            /**
             * Tracks form errors for Google Tag Manager and adds the Hotjar tag records.
             *
             * @param {string} fieldName      The field name to check if its valid or not.
             * @param {string} message        The message to be displayed if the field has an error.
             */
            pushGtmErrorEvent: function (fieldName, message) {
                    gtag('event', 'form_error', {
                        'action': fieldName,
                        'label': message
                    });

                    $window.hj = $window.hj || function () {
                            ($window.hj.q = $window.hj.q || []).push(arguments);
                        };
                    $window.hj('tagRecording', ['validation_error']);
            },
        };
    }
})();
