/**
 * Provides a callback function for parsing shipping configuration parameters into shipping price information.
 */
(function() {
    'use strict';

    angular.module('app').factory('ShippingParserFactory', ShippingParserFactory);

    function ShippingParserFactory() {
        return {get: shippingParser};
    }

    function shippingParser(holidays) {
        return parseShipping;

        /**
         * Given a set of shipping instructions that includes four coefficients ('constant', 'first', 'second' and
         * 'third') for a third degree polynomial and a 'max' (price) - provides an associative array where 'date'
         * corresponds to the business day that is 'day' number of days after today, and where 'price' is the perBand
         * shipping price for that date.
         *
         * @callback ShippingParser~parseShipping
         * @param {array} shipping An array of Object that have 'constant', 'first', 'second' and 'third', and 'max'
         *                         properties.
         * @param {Number} qty     The current ordered quantity.
         * @param {string} style   The current style selected by customer.
         * @param {string} size    The current size selected by customer.
         * @return {[{date:Date, day:string, price:float}]} The per band shipping costs per date.
         */
        function parseShipping(shipping, qty, style, size) {
            var parsed = [];
            var prices = [];

            var length = shipping.length;
            for (var i = 0; i < length; i++) {
                parsed.push({'date': getShippingDay(shipping[i].day), 'day': shipping[i].day});
                prices.push(
                    shipPricePerBand(
                        shipping[i].max,
                        shipping[i].constant,
                        shipping[i].first,
                        shipping[i].second,
                        shipping[i].third,
                        qty
                    )
                );
            }

            var current, previous;

            // Creates a moment using current Datetime and changes the timezone to America/Chicago / Central Time
            var factory = moment.tz(new Date(), 'America/Chicago');
            var weekend  = factory.day() === 0 || factory.day() === 6;
            //if(style === 'Debossed' || style === 'Blank'){
            //if( style === 'Blank' ) {
            if(style === 'Blank'){
                weekend= factory.day() === 6;
                if ((!weekend) && qty <= 500 && size === '1/2 Inch') {
                    parsed[0].name = 'Expedited';
                    if(parseInt(factory.hours()) >= 12){
                        for (var j = 0; j < length; j++) {
                            parsed[j].date = getShippingDay(shipping[j].day + 1);
                            parsed[j].day = shipping[j].day + 1;
                        }
                    }
                } else {
                    parsed.splice(0,1);
                    prices.splice(0,1);
                    length -= 1;
                }
            }

            // The first two prices should be unaltered to allow us to set a different price for expedited delivery.
            parsed[0].price = prices[0];
            parsed[1].price = prices[1];

            for (i = 2; i < length; i++) {
                current = parseFloat(prices[i]);
                previous = parseFloat(prices[i -1]);

                // If the current price is not lower than the previous price, set it lower than the previous price.
                parsed[i].price = prices[i] = Math.max(0, (current < previous ? current : previous - 0.01)).toFixed(2);
            }

            // The last price is always just a penny less than the second to last.
            var final = (parseFloat(prices[length - 1]) - 0.01);
            parsed[length - 1].price = (final > 0 ? final : 0).toFixed(2);

            return parsed;
        }

        /**
         * Provides the price per band for shipping an order given the provided parameters and the current order
         * quantity.
         *
         * @param {string} constant The constant of the polynomial.
         * @param {string} first    The coefficient of the first degree term.
         * @param {string} second   The coefficient of the second degree term.
         * @param {string} third    The coefficient of the third degree term.
         * @param {string} max      The upper limit for the total shipping cost for the entire quantity.
         * @param {Number} qty      The current ordered quantity.
         * @returns {string} The per-band price of shipping a band with the given parameters (rounded to two decimals).
         */
        function shipPricePerBand(max, constant, first, second, third, qty) {
            if (qty <= 0) {
                return 0;
            }

            return Math.max(0, (Math.ceil(Math.min(
                    parseFloat(constant) + parseFloat(first) * qty + parseFloat(second) * Math.pow(qty, 2) +
                    parseFloat(third) * Math.pow(qty, 3),
                    parseFloat(max)
                ) * 100 / qty) / 100)).toFixed(2);
        }

        /**
         * Provides the business day that is 'daysToAdd' number of days after today.
         *
         * @param daysToAdd The number of business days from now of the date to be returned.
         * @returns {Date} The date that is 'daysToAdd' number of business days from today.
         */
        function getShippingDay(daysToAdd) {
            var date = new Date();
            while (daysToAdd > 0) {
                // Increment a day.
                date.setDate(date.getDate() +1);
                // If it's not a weekend or holiday then we've just added a business day, so subtract a day from the
                // goal. && !isHoliday(date)
                if (!isWeekend(date) && !isHoliday(date)){
                    --daysToAdd;
                }
            }

            date.isHoliday = isHoliday(date);

            return date;
        }

        /**
         * Indicates if the provided date is a weekend.
         *
         * @param {Date} date The date to be checked.
         * @returns {boolean} True if the provided date is a weekend, false if not.
         */
        function isWeekend(date) {
            var day = date.getDay();
            return day === 0 || day === 6;
        }

        /**
         * Indicates if the provided date is a holiday
         *
         * @param {Date} date The date to be checked
         * @return {boolean} True if the provided date is a holiday, false if not.
         */
        function isHoliday(date) {
            for (var i = 0; i < holidays.length; i++) {
                var holiday = holidays[i].nextOrLast.split('-');
                if (parseInt(holiday[2]) === parseInt(date.getDate()) &&
                    parseInt(holiday[1] - 1) === parseInt(date.getMonth())) {
                    return true;
                }
            }
        }
    }
})();
