(function() {
    'use strict';

    angular.module('app').factory('ProductFactory', ProductFactory);

    function ProductFactory($resource, $routeParams, OptionParserFactory) {
        return $resource('/product/:id', {}, {
            update: {method: 'PUT', params: {id: id}},
            get: {method: 'GET', params: {id: id}, transformResponse: transformResponse}
        });

        function id() {
            return $routeParams.id;
        }

        function transformResponse(response) {
            return OptionParserFactory.parse(angular.fromJson(response));
        }
    }
})();
