(function() {
    'use strict';

    angular.module('app').filter('customField', customField);

    function customField() {
        return filter;

        function filter(fieldName) {
            var matches = fieldName.match(
                new RegExp(
                    '(Preview Image$|^Preview|Clipart_ID$|Clipart ID$|^Delivery_Date$|^Custom_Individual_Bagging_Image$|' +
                        '^Custom Individual Bagging Image$|Band$|^Delivery Date$|^Customize In USA Option$|^SVG Image$|^Image File$)',
                    'g'
                )
            );

            return matches ? matches[0] : null;
        }
    }
})();
