(function() {
    'use strict';

    angular.module('app').controller('ClientCarouselController', ClientCarouselController);

    function ClientCarouselController($scope) {
        $scope.slides = [
            {
                image: '/images/client-logos/EVG-Logo-Square.png',
            },
            {
                image: '/images/client-logos/houston_astros_logo.png'
            },
            {
                image: '/images/client-logos/houston_zoo_logo.png'
            },
            {
                image: '/images/client-logos/amazon_logo.png'
            },
            {
                image: '/images/client-logos/american_red_cross_logo.png'
            },
            {
                image: '/images/client-logos/boy_scouts_of_america.png'
            },
            {
                image: '/images/client-logos/kroger_logo.png'
            },
            {
                image: '/images/client-logos/game_stop_logo.png'
            },
            {
                image: '/images/client-logos/girl_scouts_of_the_usa.png'
            },
            {
                image: '/images/client-logos/hasbro_logo.png'
            },
            {
                image: '/images/client-logos/hilton_brand_logo.jpg'
            },
            {
                image: '/images/client-logos/j_p_morgan_chase_logo.jpg'
            },
            {
                image: '/images/client-logos/lowes_logo.png'
            },
            {
                image: '/images/client-logos/macys_logo.png'
            },
            {
                image: '/images/client-logos/navy_seal_foundation.png'
            }
        ];
    }
})();
