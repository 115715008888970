/**
 * Provides the order as resource with RESTful routes.
 */
(function() {
    'use strict';

    angular.module('app').factory('SampleRequestFactory', SampleRequestFactory);

    function SampleRequestFactory($resource, $routeParams) {
        var endPointPrefix = '/sampleRequest/:id';
        return $resource('', {}, {
            update: {url: endPointPrefix, method: 'PUT', params: {id: id}},
            query: {url: endPointPrefix, method: 'GET', params: {id: 'index', page: 1}},
            'tracking': {url: endPointPrefix + '/addTracking', method: 'POST', params: {id: id}},
            'shippingLabel': {url: endPointPrefix + '/generateLabel', method: 'POST', params: {id: id}},
            get: {url: endPointPrefix, method: 'GET', params: {id: id}}
        });

        function id() {
            return $routeParams.id;
        }
    }
})();
