(function() {
    'use strict';

    angular.module('app').factory('ReviewFactory', ReviewFactory);

    function ReviewFactory($resource, $routeParams) {
        return $resource('', {}, {
            setExempt: {url: 'setExempt', method: 'POST', params: {order:order}, isArray:true},
            removeExempt: {url: 'removeExempt', method: 'POST', params: {order:order}, isArray:true}
        });

        function order(){
            return $routeParams.order;
        }
    }
})();


