(function() {
    'use strict';

    angular.module('app').factory('MetadataFactory', MetadataFactory);

    /**
     * The factory to handle the metadata of the project
     * @param  $resource The restful factory resource object injected.
     * @return $resource The restful factory resource object parsed.
     */
    function MetadataFactory($resource) {
        return $resource(':model/metadata/');
    }
})();
