/**
 * Provides a factory as a resource with RESTful routes.
 */
(function () {
    'use strict';
    angular.module('app').factory('HubspotFactory', HubspotFactory);

    function HubspotFactory($resource, $routeParams) {

        function email() {
            return $routeParams.email;
        }

        function amt() {
            return $routeParams.amt;
        }

        function name() {
            return $routeParams.name;
        }

        function phone() {
            return $routeParams.phone;
        }

        function orderid() {
            return $routeParams.orderid;
        }

        return $resource('', {}, {
            update: {url:'hubspot',method: 'POST', params: {id: email, amt: amt}},
            update_wname: {url:'hubspot',method: 'POST', params: {id: email, amt: amt, name: name, phone:phone}},
            delete: {url:'hubspotdelete',method: 'DELETE', params: {id: email}},
            delete_wid: {url:'hubspotdelete',method: 'DELETE', params: {id: email, orderid:orderid}},
        });
    }
})();
