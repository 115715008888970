/**
 * Provides a factory as a resource with RESTful routes.
 */
(function() {
    'use strict';
    angular.module('app').factory('InventoryItemFactory', InventoryItemFactory);

    function InventoryItemFactory($resource, $routeParams) {
        return $resource('/inventoryItem/:id', {}, {
            update: {method: 'PUT', params: {id: id}},
            index: {isArray:true},
            get: {method: 'GET', params: {id: id}}
        });

        function id() {
            return $routeParams.id;
        }
    }
})();
