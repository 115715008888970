/**
 * Provides a factory as a resource with RESTful routes.
 */
(function() {
    'use strict';
    angular.module('app').factory('InternationalRatesFactory', InternationalRatesFactory);

    function InternationalRatesFactory($resource) {

        return $resource('/internationalShipping/:id', {}, {
            internationalShip: {method:'GET', params: {id: 'internationalShip'}},
            countryList: {method:'GET', params: {id: 'countryList'}}
        });
    }

})();
