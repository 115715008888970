(function() {

    /**
     * @requires angular-resource.js
     */
    angular.module(
        'app',
        [
            'angularSpectrumColorpicker',
            'app.controller',
            'app.factory',
            'ngFileUpload',
            'ngAnimate',
            'rzModule',
            'slickCarousel'
        ],
        config
    );

    function config($interpolateProvider) {
        $interpolateProvider.startSymbol('{[');
        $interpolateProvider.endSymbol(']}');
    }

    // Initialize the factories list on our app.
    angular.module('app.factory', ['ngResource']);

    // Initialize the controllers list on our app.
    angular.module('app.controller', []);

})();

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function() {

    $(document).foundation();

    // Gallery
    // - - - - - - - - - - - - - - - - - - - - - - - - - - -
    $(".picture-gallery").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        },
        zoom: {
            enabled: true,
            duration: 300
        },
        image: {
            tError: "<a href='%url%'>The image #%curr%</a> could not be loaded.",
        }
    });

    // Homepage Banner
    // - - - - - - - - - - - - - - - - - - - - - - - - - - -
    $('.banner__point').click(function() {
        var bannerID = $(this).attr('data-banner-id');
        $('.banner__point').removeClass('js-active');
        $(this).addClass('js-active');
        $('.banner__content').fadeOut();
        $('#' + bannerID).fadeIn();
    });

});
