(function() {
    'use strict';

    angular.module('app').factory('CartCouponCodeFactory', CartCouponCodeFactory);

    function CartCouponCodeFactory($resource) {
        return $resource('/cartCouponCode/:id', {}, {
            isInactive: {method: 'GET', params: {id: 'isInactive'}, isArray:true}
        });
    }
})();
