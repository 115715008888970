(function() {
    'use strict';

    angular.module('app').factory('StyleFactory', StyleFactory);

    function StyleFactory($resource, $routeParams) {
        return $resource('/style/:id', {}, {
            update: {method: 'PUT', params: {id: id}},
            get: {method: 'GET', params: {id: id}}
        });


        function id() {
            return $routeParams.id;
        }
    }
})();
