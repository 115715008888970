(function () {
    'use strict';

    angular.module('app').filter('factoryQuantity', factoryQuantity);

    /**
     * Provides a filter for turning a customer formatted version of a quantity into a production format.
     *
     * @returns {modify} The function that transforms the value.
     */
    function factoryQuantity() {
        return modify;

        function modify(value) {
            // Look for quantities of the form "# + # Free"
            var customerFormattedQuantities = value.match(/(\d+ \+ \d+ Free)/g);

            if (customerFormattedQuantities) {
                // Loop through found customer formatted quantities.
                for (var i = 0; i < customerFormattedQuantities.length; i++) {
                    // Find the base and "Free" quantities in each.
                    var individualQuantities = customerFormattedQuantities[i].match(/\d+/g);

                    var sum = 0;
                    // Sum the base and free quantities.
                    for (var j = 0; j < individualQuantities.length; j++) {
                        sum += parseInt(individualQuantities[j]);
                    }

                    // Replace the customer quantity with one formatted for the factory.
                    value = value.replace(customerFormattedQuantities[i], sum);
                }
            }

            return value;
        }
    }
})();
