(function () {
    'use strict';

    angular.module('app').directive('stringToNumber', stringToNumber);

    function stringToNumber() {
        return {restrict: 'A', require: 'ngModel', link: link};

        function link(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(value) {
                return '' + value;
            });

            ngModel.$formatters.push(function(value) {
                return parseFloat(value);
            });
        }
    }
})();
