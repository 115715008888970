(function() {
    'use strict';

    angular.module('app').directive('bandPreviewZoomer', bandPreviewZoomer);

    function bandPreviewZoomer() {
        return {
            restrict: 'A',
            link: link,
        };

        function link($scope, $element) {
            $element.ready(apply);

            function apply() {
                var $zoomIn = $element.find('.js-zoom-in-button');
                var $zoomOut = $element.find('.js-zoom-out-button');
                var $bandPreview = $element.find('.o-preview');

                $zoomIn.on('click', function() {
                    $bandPreview.addClass('js-zoom-in');
                });

                $zoomOut.on('click', function() {
                    $bandPreview.removeClass('js-zoom-in');
                });
            }
        }
    }

})();

