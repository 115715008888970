/**
 * Provides a factory as a resource with RESTful routes.
 */
(function() {
    'use strict';

    angular.module('app').factory('FactoryFactory', FactoryFactory);

    function FactoryFactory($resource, $routeParams) {
        return $resource('/factory/:id', {id:'@id'}, {
            update: {method: 'PUT'},
            get: {method: 'GET', params: {id: id}}
        });

        function id() {
            return $routeParams.id;
        }
    }
})();
