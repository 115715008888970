(function() {
    'use strict';

    angular.module('app').factory('ClientModalFactory', ClientModalFactory);

    function ClientModalFactory($resource) {
        return $resource('', {}, {
            check: {url:'/manage/save/:name',method: 'POST', params: {id:'name'}, isArray:true}
        });
    }
})();
