/**
 * Used for displaying and editing cart items.
 */
(function() {
    'use strict';

    angular.module('app').controller('PagesHeaderController', PagesHeaderController);

    function PagesHeaderController($scope, CartFactory) {
        $scope.cartStatus = false;
        $scope.showCart = showCart;
        $scope.hideCart = hideCart;
        $scope.cartItemsQty = cartItemsQty;
        $scope.cart = CartFactory;
        /**
         * Calculates total quantity of order in cart
         * @return {integer}
         */
        function cartItemsQty() {
            var qty = 0;
            angular.forEach($scope.cart.items, function(item) {
                qty += item.quantity;
            });
            return qty;
        }

        function showCart() {
            $scope.cartStatus = true;
        }

        function hideCart(clickEvent) {
            if ($(clickEvent.target).is('.cart-sidebar')) {
                $scope.cartStatus = true;
            } else if ($(clickEvent.target).is('.cart-sidebar__back')) {
                $scope.cartStatus = false;
            } else if ($(clickEvent.target).parents().is('.cart-sidebar')) {
                $scope.cartStatus = true;
            } else {
                $scope.cartStatus = false;
            }
        }
    }
})();
