(function() {
    'use strict';

    angular.module('app').factory('CartFactory', CartFactory);

    function CartFactory(CartItemFactory, CartCouponCodeFactory) {
        var items = CartItemFactory.query();
        var coupon = CartCouponCodeFactory.get();
        return {
            items: items,
            coupon: coupon,
            inactiveCoupon: inactiveCoupon,
            subTotal: calcCartSubtotal,
            cartTotal: calcCartTotal,
            calcCartDiscount: calcCartDiscount,
            cartQtyCallback: cartQtyCallback,
            cartPromoQtyCallBack: cartPromoQtyCallBack,
            removeItem: removeItem,
            pushItem: pushItem,
            pushCoupon: pushCoupon,
            couponRefresh : couponRefresh
        };

        function calcCartSubtotal() {
            for(var i = 0, total = 0; i < items.length; i++) {
                total += ( items[i].price * items[i].quantity );

                if (items[i].hasOwnProperty('setUpFee')) {
                    total += items[i].setUpFee;
                }
            }

            return total;
        }

        function calcCartDiscount() {
            var cartDiscount = 0;
            if(typeof coupon !== 'undefined' &&
                typeof coupon.discount !== 'undefined' &&
                typeof coupon.order_minimum !== 'undefined' &&
                calcCartSubtotal() >= coupon.order_minimum) {
                if(coupon.type === 'percentage') {
                    cartDiscount = ( calcCartSubtotal() * coupon.discount ) / 100;
                    if(coupon.max_discount !== null &&
                        coupon.max_discount > 0 &&
                        cartDiscount >= coupon.max_discount){
                        cartDiscount = coupon.max_discount;
                    }
                } else {
                    cartDiscount = coupon.discount > calcCartSubtotal() ? calcCartSubtotal() : coupon.discount;
                }
            }

            // We have to jump through some hoops here to make sure the a 5 in the thousandths rounds up (i.e. 2.295)
            return (Math.round(cartDiscount.toFixed(3)*100)/100).toFixed(2);
        }

        function inactiveCoupon() {
            coupon = [];
        }

        function calcCartTotal() {
            var discount = calcCartDiscount();
            var subTotal = calcCartSubtotal();
            return discount !== null && typeof discount !== 'undefined' ?
                parseFloat(subTotal) - parseFloat(discount) : parseFloat(subTotal);
        }

        function cartQtyCallback() {
            function calcCartQty() {
                for(var i = 0, qty = 0; i < items.length; i++) {
                    qty += items[i].quantity;
                }
                return qty;
            }
            return calcCartQty();
        }

        function couponRefresh()
        {
            CartCouponCodeFactory.get().$promise.then(finished);
        }

        function finished(data)
        {
            coupon = data;
        }

        function cartPromoQtyCallBack() {
            function cartPromoQty() {
                for(var i = 0, qty = 0; i < items.length; i++) {
                    qty += items[i].promotionalQuantity;
                }
                return qty;
            }
            return cartPromoQty();
        }

        function removeItem(index) {
            CartItemFactory.delete({id: index}).$promise.then(removeLocal);

            function removeLocal() {
                items.splice(index, 1);
            }
        }

        function pushItem(item) {
            return CartItemFactory.save(item).$promise.then(pushLocal);

            function pushLocal() {
                items.push(item);
            }
        }

        function pushCoupon(newCoupon) {
            coupon = newCoupon;
        }
    }
})();
