/**
 * Provides the order as resource with RESTful routes.
 */
(function() {
    'use strict';

    angular.module('app').factory('UserFactory', UserFactory);

    function UserFactory($resource) {
        return $resource('/me/', {}, {
            get: {method: 'GET', params: {}}
        });
    }
})();
