(function() {
    'use strict';

    angular.module('app').factory('FilterFactory', FilterFactory);

    function FilterFactory($resource) {
        return $resource('/filter/', {}, {
            check: {method: 'POST', params: {id:'data'}, isArray:true}
        });
    }
})();
